<template>
  <b-form
    @submit.prevent="change"
    class="w-100 h-100 p-4 d-flex flex-column justify-content-between"
  >
    <validation-observer ref="operacao">
      <div class="row">
        <div class="col-lg-3 mb-3">
          <label for="dataOperacao" class="form-label required"
            >Data Operação</label
          >
          <b-form-input
            id="dataOperacao"
            v-model="operacao.dataOperacao"
            type="text"
            v-maska="'##/##/#### ##:##'"
            @input="$v.operacao.dataOperacao.$touch()"
            :state="$v.operacao.dataOperacao.$error ? false : null"
          />
          <b-form-invalid-feedback
            v-if="$v.operacao.dataOperacao.$error"
            :state="$v.operacao.dataOperacao.$error ? false : null"
          >
            <div v-if="!$v.operacao.dataOperacao.required">
              É necessário informar a data de entrada
            </div>
            <div v-if="!$v.operacao.dataOperacao.mascaraDataEntradaInvalid">
              Informe a data e hora no padrão DD/MM/YYYY HH:MM
            </div>
          </b-form-invalid-feedback>
        </div>
        <div class="col-lg-3 mb-3">
          <label for="dta" class="form-label required">DTA</label>
          <b-form-input
            id="dta"
            v-model="operacao.dta"
            type="text"
            v-maska="'##/#######-#'"
            placeholder="00/0000000-0"
            @blur="$v.operacao.dta.$touch()"
            @input="getDTA($event)"
            @keyup="validaTamanhoDTA()"
            :state="$v.operacao.dta.$error ? false : null"
          />
          <b-form-invalid-feedback
            v-if="$v.operacao.dta.$error"
            :state="$v.operacao.dta.$error ? false : null"
          >
            <div v-if="!$v.operacao.dta.required">
              É necessário informar o DTA
            </div>
            <div v-if="!$v.operacao.dta.validMascaraDTA">
              Informe um número de DTA válido
            </div>
          </b-form-invalid-feedback>
        </div>
        <div class="col-lg-3 mb-3">
          <label for="mercadoria" class="form-label required"
            >Mercadorias</label
          >
          <b-form-select
            :options="optionsMercadoria"
            value-field="id"
            @update-items="updateItemsAutoSelectMercadoria"
            text-field="descricao"
            v-model="operacao.container"
            @change="atualizarLocalOrigem($event)"
          ></b-form-select>
        </div>

        <div class="col-lg-3 mb-3">
          <label for="localOrigem" class="form-label required">
            Local de Origem
          </label>
          <v-autocomplete
            id="localOrigem"
            :items="autoSelectArmazenamento.items"
            v-model="operacao.localOrigem"
            :get-label="getLabelAutoSelectArmazenamento"
            @update-items="updateItemsAutoSelectArmazenamento"
            :component-item="autoSelectArmazenamento.template"
            :min-len="autoSelectArmazenamento.length"
            :auto-select-one-item="autoSelectArmazenamento.autoSelectOneItem"
            :input-attrs="
              $v.operacao.localOrigem.$error
                ? { class: 'form-control is-invalid' }
                : { class: 'form-control' }
            "
            @blur="$v.operacao.localOrigem.$touch()"
            :state="$v.operacao.localOrigem.$error ? false : null"
            :disabled="desabilitados.localOrigem"
          >
          </v-autocomplete>
          <b-form-invalid-feedback
            v-if="$v.operacao.localOrigem.$error"
            :state="$v.operacao.localOrigem.$error ? false : null"
          >
            <div v-if="!$v.operacao.localOrigem.required">
              É necessário informar o Local de origem
            </div>
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 mb-3">
          <label for="operador" class="form-label required"
            >Operador Portuário</label
          >
          <v-autocomplete
            id="operador"
            :items="autoSelectOperador.items"
            v-model="operacao.operador"
            :get-label="getLabelAutoSelectOperador"
            @update-items="updateItemsAutoSelectOperador"
            :component-item="autoSelectOperador.template"
            :min-len="autoSelectOperador.length"
            :auto-select-one-item="autoSelectOperador.autoSelectOneItem"
            :input-attrs="
              $v.operacao.operador.$error
                ? { class: 'form-control is-invalid' }
                : { class: 'form-control' }
            "
            @blur="$v.operacao.operador.$touch()"
            :state="$v.operacao.operador.$error ? false : null"
          >
          </v-autocomplete>
          <b-form-invalid-feedback
            v-if="$v.operacao.operador.$error"
            :state="$v.operacao.operador.$error ? false : null"
          >
            <div v-if="!$v.operacao.operador.required">
              É necessário informar o operador portuário
            </div>
          </b-form-invalid-feedback>
        </div>
        <div class="col-lg-3 mb-3">
          <label for="placaTrator" class="form-label required"
            >Placa Trator</label
          >
          <b-form-input
            type="text"
            id="placaTrator"
            v-model="operacao.placaTrator"
            :placeholder="inputNumero.placeholder"
            :maxlength="inputNumero.maxlength"
            v-mask="inputNumero.mask"
            @blur="clearIncompletedField($event)"
            @keyup="definirMascaraInputNumero()"
          ></b-form-input>
        </div>
        <div class="col-lg-3 mb-3">
          <label for="placaReboque" class="form-label">Placa Reboque</label>
          <b-form-input
            type="text"
            id="placaReboque"
            v-model="operacao.placaReboque"
            :placeholder="inputNumeroReboque.placeholder"
            :maxlength="inputNumeroReboque.maxlength"
            v-mask="inputNumeroReboque.mask"
            @blur="clearIncompletedField($event)"
            @keyup="definirMascaraInputNumeroReboque()"
          ></b-form-input>
        </div>
        <div class="col-lg-3 mb-3">
          <label for="observacoes" class="form-label">Observações</label>
          <b-form-textarea
            v-model="operacao.observacoes"
            id="observacoes"
          ></b-form-textarea>
          <b-form-invalid-feedback>
            <div>É necessário informar o</div>
          </b-form-invalid-feedback>
        </div>
      </div>
      <div class="row"></div>

      <div class="pt-4 w-100 d-flex justify-content-center">
        <router-link
          class="btn btn-light"
          :to="{ name: 'PesquisarOperacaoPortuariaMercadoria' }"
          ><i class="fas fa-times"></i> Cancelar</router-link
        >
        <button
          type="submit"
          class="btn btn-success"
          :disabled="$v.operacao.$invalid"
        >
          <i class="fas fa-check"></i> Salvar
        </button>
      </div>
    </validation-observer>
  </b-form>
</template>

<script>
import { OperacaoPortuariaMercadoriaService } from "@/services";
import { OperadorPortuarioService } from "@/services";
import { ContainerService } from "@/services";
import { MercadoriaService } from "@/services";
import { required } from "vuelidate/lib/validators";
import MascaraDTA from "@/validators/operacaoPortuaria/mascaraDTA";
import moment from "moment";
import ItemTemplateAutoSelectOperador from "../components/ItemTemplateAutoSelectOperador.vue";
import ToastHelper from "../../../helpers/toastHelper";
import Autocomplete from "v-autocomplete";
import ItemTemplateAutoSelectArmazenamento from "../../operacaoPortuaria/components/ItemTemplateAutoSelectArmazenamento.vue";
import { ArmazenamentoService } from "../../../services/armazenamentoService";

const formatoDatatime = data =>
  moment(data, "DD/MM/YYYY HH:mm", true).isValid();

export default {
  props: {
    mountedForm: {
      type: Object
    }
  },
  components: {
    "v-autocomplete": Autocomplete
  },
  data: function() {
    return {
      autoSelectArmazenamento: {
        length: 1,
        autoSelectOneItem: false,
        items: [],
        template: ItemTemplateAutoSelectArmazenamento
      },
      autoSelectOperador: {
        length: 1,
        autoSelectOneItem: false,
        items: [],
        template: ItemTemplateAutoSelectOperador
      },
      containers: [],
      localOrigemDescricao: "",
      operacao: {
        dta: "",
        dataOperacao: null,
        container: null,
        localOrigem: null,
        placaTrator: null,
        placaReboque: null,
        operador: null,
        observacoes: null
        //operacao_portuaria_fk: null,
      },
      optionsMercadoria: [{ id: null, descricao: "" }],
      optionsLocalOrigem: [{ id: null, descricao: "" }],
      localOrigem: [],
      inputNumero: {
        placeholder: "AAA-9999 ou AAA9A99",
        maxlength: "",
        mask: "AAA#X"
      },
      inputNumeroReboque: {
        placeholder: "AAA-9999 ou AAA9A99",
        maxlength: "",
        mask: "AAA#X"
      },
      desabilitados: {
        dataOperacao: true,
        localOrigem: true
      }
    };
  },
  validations: {
    operacao: {
      dataOperacao: {
        required,
        mascaraDataEntradaInvalid: value => formatoDatatime(value)
      },
      dta: {
        required,
        validMascaraDTA: value => MascaraDTA.isValid(value)
      },
      localOrigem: { required },
      placaTrator: { required },
      container: { required },
      operador: { required }
    }
  },
  methods: {
    atualizarLocalOrigem(containerId) {
      ContainerService.buscarPorId({ id: containerId }).then(resposta => {
        this.operacao.localOrigem = resposta.data.local;
      });
    },
    getLabelAutoSelectOperador(item) {
      return item == null ? null : item.razaoSocial;
    },
    getLabelAutoSelectArmazenamento(item) {
      return item == null ? null : item.descricao;
    },
    updateItemsAutoSelectArmazenamento(query) {
      this.autoSelectArmazenamento.items = [];
      ArmazenamentoService.get({
        currentPage: 0,
        pageSize: 15,
        descricao: query
      }).then(resposta => {
        resposta.data.content.forEach(localOrigem => {
          this.autoSelectArmazenamento.items.push({
            ...localOrigem
          });
        });
      });
    },
    updateItemsAutoSelectMercadoria(query) {
      this.autoSelectMercadoria.items = [];
      MercadoriaService.get({
        currentPage: 0,
        pageSize: 15,
        descricao: query
      }).then(resposta => {
        resposta.data.content.forEach(mercadoria => {
          this.autoSelectMercadoria.items.push({
            ...mercadoria
          });
        });
      });
    },
    updateItemsAutoSelectOperador(query) {
      this.autoSelectOperador.items = [];
      OperadorPortuarioService.getEmpresas({
        currentPage: 0,
        pageSize: 15,
        razaoSocial: query
      }).then(resposta => {
        resposta.data.content.forEach(operador => {
          this.autoSelectOperador.items.push({
            ...operador
          });
        });
      });
    },
    removeDuplicates(a, key) {
      return [...new Map(a.map(x => [key(x), x])).values()];
    },
    validaTamanhoDTA() {
      let dta = this.operacao.dta;
      dta = dta.replace(/[^\d]+/g, "");
      if (dta.length < 10) {
        this.optionsMercadoria = [];
        this.operacao.localOrigem = null;
        this.localOrigemDescricao = "";
      }
    },
    getDTA() {
      let dta = this.operacao.dta;
      dta = dta.replace(/[^\d]+/g, "");
      if (dta.length == 10) {
        OperacaoPortuariaMercadoriaService.getDtaMercadoria({ dta })
          .then(resposta => {
            this.optionsMercadoria = [];
            this.optionsMercadoria.push({ id: null, descricao: "" });

            this.containers = [];
            this.containers = resposta.data;

            resposta.data.forEach(container => {
              this.optionsMercadoria.push({
                id: container.id,
                descricao:
                  container.mercadoria.descricao + " - " + container.numero
              });
            });
          })
          .catch(error => {
            if (error.response.data.message != undefined && dta.length == 10) {
              ToastHelper.dangerMessage(error.response.data.message);
            }
            this.optionsMercadoria = [];
            // this.operacao.localOrigem = null;
            // this.localOrigemDescricao = "";
          });
      }
    },
    clearIncompletedField() {
      if (
        this.operacao.placaTrator &&
        this.operacao.placaTrator.length < this.inputNumero.maxlength
      ) {
        this.operacao.placaTrator = "";
      }
      if (
        this.operacao.placaReboque &&
        this.operacao.placaReboque.length < this.inputNumeroReboque.maxlength
      ) {
        this.operacao.placaReboque = "";
      }
    },
    setData(operacao) {
      (this.operacao = {
        dta: operacao.dta,
        dataOperacao: operacao.dataOperacao,
        container: operacao.container,
        localOrigem: operacao.localOrigem,
        placaTrator: operacao.placaTrator,
        placaReboque: operacao.placaReboque,
        mercadoria: operacao.mercadoria,
        operador: operacao.operador,
        observacoes: operacao.observacoes
        //operacao_portuaria_fk: operacao.operacao_portuaria_fk
      }),
        this.autoSelectArmazenamento.items.push(operacao.localOrigem);
      this.autoSelectMercadoria.items.push(operacao.mercadoria);
      this.autoSelectOperador.items.push(operacao.operador);
    },
    reset() {
      requestAnimationFrame(() => {
        //this.$refs.movimentacaoFormulario.reset();
      });
      this.preencheDataEntrada();
    },
    change() {
      this.$emit("change", this.operacao);
    },
    preencheDataEntrada() {
      //setar data e hora atual em Data operação
      this.operacao.dataOperacao = moment().format("DD/MM/YYYY HH:mm");
    },
    definirMascaraInputNumero() {
      let numero = this.operacao.placaTrator.replace(/[^a-zA-Z0-9]+/g, "");
      this.inputNumero.mask = "AAA#X";
      if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}"))) {
        this.inputNumero.mask = "AAA#X##";
        this.inputNumero.maxlength = 7;
      } else if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{2}"))) {
        this.inputNumero.mask = "AAA-####";
        this.inputNumero.maxlength = 8;
      }
      this.operacao.placaTrator = this.operacao.placaTrator.toUpperCase();
    },
    definirMascaraInputNumeroReboque() {
      let numero = this.operacao.placaReboque.replace(/[^a-zA-Z0-9]+/g, "");
      this.inputNumeroReboque.mask = "AAA#X";
      if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}"))) {
        this.inputNumeroReboque.mask = "AAA#X##";
        this.inputNumeroReboque.maxlength = 7;
      } else if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{2}"))) {
        this.inputNumeroReboque.mask = "AAA-####";
        this.inputNumeroReboque.maxlength = 8;
      }
      this.operacao.placaReboque = this.operacao.placaReboque.toUpperCase();
    },
    setPlacaTrator(placa) {
      let numero = placa?.replace(/[^a-zA-Z0-9]+/g, "");
      if (numero?.match(new RegExp("[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}"))) {
        this.inputNumero.mask = "AAA#X##";
        this.inputNumero.maxlength = 7;
      } else if (numero?.match(new RegExp("[a-zA-Z]{3}[0-9]{2}"))) {
        this.inputNumero.mask = "AAA-####";
        this.inputNumero.maxlength = 8;
      }
    },
    setPlacaReboque(placa) {
      if (placa == "") {
        return true;
      }
      if (placa == null) {
        return true;
      }
      let numero = placa.replace(/[^a-zA-Z0-9]+/g, "");
      if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}"))) {
        this.inputNumeroReboque.mask = "AAA#A##";
        this.inputNumeroReboque.maxlength = 7;
      } else if (numero.match(new RegExp("[a-zA-Z]{3}[0-9]{2}"))) {
        this.inputNumeroReboque.mask = "AAA-####";
        this.inputNumeroReboque.maxlength = 8;
      }
    }
  },
  created() {
    this.preencheDataEntrada();

    if (this.mountedForm) {
      this.setPlacaTrator(this.mountedForm.placaTrator);
      this.setPlacaReboque(this.mountedForm.placaReboque);
      this.operacao = this.mountedForm;

      this.operacao.localOrigem = this.mountedForm.armlocalOrigem;
      this.autoSelectArmazenamento.items.push(this.operacao.localOrigem);

      this.operacao.operador = this.mountedForm.empresaOperador;
      this.autoSelectOperador.items.push(this.operacao.operador);
    }
  }
};
</script>
